import { App, reactive, ref } from "vue";
import { EventMessage, EventMessageUtils, EventType, InteractionStatus, PublicClientApplication, AccountInfo, AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-browser";
import { tokenRequest } from "@/authConfig";
import { parseJwt } from "@/utils/other";

type AccountIdentifiers = Partial<Pick<AccountInfo, "homeAccountId"|"localAccountId"|"username">>;

/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA 
 * @param arrayB 
 */
function accountArraysAreEqual(arrayA: Array<AccountIdentifiers>, arrayB: Array<AccountIdentifiers>): boolean {
    if (arrayA.length !== arrayB.length) {
        return false;
    }

    const comparisonArray = [...arrayB];

    return arrayA.every((elementA) => {
        const elementB = comparisonArray.shift();
        if (!elementA || !elementB) {
            return false;
        }

        return (elementA.homeAccountId === elementB.homeAccountId) && 
               (elementA.localAccountId === elementB.localAccountId) &&
               (elementA.username === elementB.username);
    });
}

export interface MsalPluginContext {
    instance: PublicClientApplication
    account: AccountInfo | null
    roles: string[]
    accessToken: string
}

const msalInitialized = ref(false);

export const msalPlugin = {
    install: (app: App, msalInstance: PublicClientApplication) => {

        const state = reactive<MsalPluginContext>({
            instance: msalInstance,
            account: null,
            roles: [],
            accessToken: ''
        });

        app.config.globalProperties.$msal = state;

        const selectAccount = (acc: AccountInfo) => {
            console.log('selectAccount', acc);
            state.account = acc;
            msalInstance.setActiveAccount(state.account);

            msalInstance.acquireTokenSilent(tokenRequest)
                .catch(error => {
                    console.warn("silent token acquisition fails. acquiring token using redirect");
                    if (error instanceof InteractionRequiredAuthError) {
                        // fallback to interaction when silent call fails
                        return msalInstance.acquireTokenPopup(tokenRequest);
                    } else {
                        console.warn(error);   
                    }
                })
        }

        const deselectAccount = () => {
            state.account = null;
            state.roles = [];
            state.accessToken = '';
        }

        // this is not added, because main.ts adds it already
        msalInstance.addEventCallback((message: EventMessage) => {
            console.log('msal Event', message);

            if (message.eventType === EventType.INITIALIZE_END) {
                msalInitialized.value = true;

                const accounts = msalInstance.getAllAccounts();
                if (accounts.length > 0) {
                    selectAccount(accounts[0]);
                }
            }

            if (message.eventType === EventType.LOGIN_SUCCESS && message.payload) {
                const payload = message.payload as AuthenticationResult;
                selectAccount(payload.account)
            }

            if (message.eventType === EventType.LOGOUT_SUCCESS ) {
                sessionStorage.removeItem("accessToken");
                sessionStorage.removeItem("accessTokenExpires");
                sessionStorage.removeItem("roles");
                deselectAccount();
            }

            if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && message.payload) {
                const response = message.payload as AuthenticationResult
                const parsedAccessToken = parseJwt(response.accessToken)
                state.roles = parsedAccessToken.roles;
                state.accessToken = response.accessToken;

                console.log('accessToken', response, parsedAccessToken);
                sessionStorage.setItem("accessToken", response.accessToken);
                const expiresOn = response.expiresOn ? response.expiresOn : new Date()
                sessionStorage.setItem("accessTokenExpires", expiresOn.getTime().toString());
                sessionStorage.setItem("roles", state.roles.join(','));
            }
        });

        msalInstance.initialize();
    },
    isReady: () => {
        if ( msalInitialized.value ) {
            return Promise.resolve();
        }
        const startTime = Date.now()
        return new Promise<void>((resolve, reject) => {
            const timeout = 10000;
            const interval = 20;
            (function waitForInitialization(){
                if ( msalInitialized.value ) {
                    return resolve();
                }
                if ( Date.now()-startTime > timeout ) {
                    return reject();
                }
                setTimeout(waitForInitialization, interval);
            })();
        })
    }
}
// is two objects has the same properties and values
export const deepEqual = (x: any, y: any): boolean => {
  // https://stackoverflow.com/a/32922084/22658045
  const ok = Object.keys
  const tx = typeof x
  const ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? (ok(x).length === ok(y).length && ok(x).every(key => deepEqual(x[key], y[key])))
    : (x === y)
}

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
import { MsalPluginContext } from "@/plugins/msalPlugin";
import { AccountInfo, InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import { computed, getCurrentInstance, Ref, toRefs } from "vue";

export type MsalContext = {
    instance: PublicClientApplication,
    accounts: Ref<AccountInfo[]>,
    inProgress: Ref<InteractionStatus>
    account: Ref<AccountInfo|null>
    roles: Ref<string[]>
    accessToken: Ref<string>,
    isAuthenticated: Ref<boolean>
}

export function useMsal() {
    const internalInstance = getCurrentInstance();
    if (!internalInstance) {
        throw "useMsal() cannot be called outside the setup() function of a component";
    }
    const { instance, account, roles, accessToken } = toRefs<MsalPluginContext>(internalInstance.appContext.config.globalProperties.$msal);

    if (!instance) {
        throw "Please install the msalPlugin";
    }

    const roleMap = [
        {
            id: 'user',
            role: 'Positioning.User',
            label: 'User'
        },
        {
            id: 'admin',
            role: 'Positioning.Admin',
            label: 'Admin'
        }
    ];

    const currentUser = computed(() => {
        const isAuthenticated = account.value !== null;
        const name = account.value?.name || '';
        const username = account.value?.username || '';
        const roleDetails = roleMap.filter(r=>roles.value.includes(r.role))
        const isRoleUser = roleDetails.filter(r=>r.id==='user').length > 0
        const isRoleAdmin = roleDetails.filter(r=>r.id==='admin').length > 0
        return {
            isAuthenticated,
            name,
            username,
            roleDetails,
            isRoleUser,
            isRoleAdmin
        }
    })

    return {
        instance: instance.value,
        account,
        roles,
        accessToken,
        currentUser
    }
}
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Notifications from '@kyvg/vue3-notification'
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { CustomNavigationClient } from "./router/NavigationClient";
import './index.css'


// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

const app = createApp(App);

app.use(Notifications)
app.use(msalPlugin, msalInstance);
msalPlugin.isReady().then(()=>{
  console.log('MSAL READY!!!');
  app.use(router);
  router.isReady().then(() => {
    console.log('Router READY!!!');
    app.mount('#app');
  });
})

import { msalInstance } from "@/authConfig";
import { RouteLocationNormalized, Router } from "vue-router";

export function registerGuard(router: Router) {
    router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        if (to.meta.requiresAuth) {
            const account = msalInstance.getActiveAccount();
            // console.log('GUARD', account);
            return account ? true : '/';
        }
    
        return true;
    });
}
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { registerGuard } from "./Guard";
import HomeView from '../views/HomeView.vue'

// route meta typescript
declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
    requiresRole?: string[]
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/positioning',
    name: 'positioning',
    meta: {
      requiresAuth: true,
      requiresRole: ['user', 'admin']
    },
    // route level code-splitting
    // this generates a separate chunk (positioning.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "positioning" */ '../views/PositioningView.vue')
  },
  {
    path: '/layouts',
    name: 'layouts',
    meta: {
      requiresAuth: true,
      requiresRole: ['admin']
    },
    // route level code-splitting
    // this generates a separate chunk (layouts.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "layouts" */ '../views/LayoutsView.vue')
  },
  {
    path: '/failed',
    name: 'failed',
    // route level code-splitting
    // this generates a separate chunk (layouts.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "layouts" */ '../views/FailedView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

registerGuard(router);

export default router
